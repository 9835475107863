import { Container, Row, Col } from 'react-bootstrap';
import { MailchimpForm } from './MailchimpForm';
import logo from '../assets/img/logo.svg';
import navIcon1 from '../assets/img/nav-icon1.svg';
import navIcon2 from '../assets/img/nav-icon2.svg';
import navIcon3 from '../assets/img/nav-icon3.svg';

export const Footer = () => {
  return (
    <footer className='footer'>
      <Container>
        <Row className='align-items-center'>
          <MailchimpForm />
          <Col size={12} sm={6}>
            <img src={logo} alt='Logo' />
          </Col>
          <Col size={12} sm={6} className='text-center text-sm-end'>
            <div className='social-icon'>
              <a href='#'>
                <img src={navIcon1} alt='Icon' />
              </a>
              <a href='#'>
                <img src={navIcon2} alt='Icon' />
              </a>
              <a href='#'>
                <img src={navIcon3} alt='Icon' />
              </a>
            </div>
            <p>Copyright 2024. All Rights Reserved</p>
          </Col>
        </Row>
      </Container>
      <div style={{marginBottom:'-30px'}}>
        <p className='text-center'>Designed & Develop by Jitendra Yadav</p>
        <p style={{ marginTop: '-5px' }} className='text-center'>
          Mob:- 7999759371
        </p>
      </div>
    </footer>
  );
};
